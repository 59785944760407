import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/people_and_change.jpg'
import person from '../../images/person.png'
import ContactText from '../../components/ContactText'

const pageTitle = "People and Change Consulting";
const pageSLug = "people-and-change-consulting";
class PeopleChange extends React.Component{
  render(){
    return(
      <Layout pageType="business-consulting">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">By working together, we can enable and empower your organization's 
            ability to lead and succeed in today's market.</h6>
        </div>
      </section>

      <section id="p_business_consulting" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    Change management is now a necessity. To be a successful business, an organization must focus on change.
                    </h3>
                   <p>Business agility has become an essential part of operating a business. Agility requires committed leaders,
                        an inspiring vision, employee experience, and the know-how to effectively and efficiently respond. 
                        Leaders must adjust their mindsets to focus on the people, not just the processes. </p>
                    <p>Our proficiencies can help organizations prosper and become market leaders in their particular field. 
                        We can help you build your strategic foundation and guide your people transformations. 
                        We are creative, strategic, flexible, and agile. We work with you to successfully implement 
                        lasting change.</p>
                    <p>Together we turn change into transformation.</p>
                   <Link to="/contact-us" className="button green">Contact us to learn more</Link>
                   <span className="break"></span>
                   <h4>Why partner with Nuerex?</h4>
                   <p>There’s no set way to design an effective change strategy. The strategy has to fit your company culture 
                       and integrate with your long term strategic goals. Every organization has its own transformation needs. 
                       That is the reason why we work together to develop the right solution for you, that includes:</p>
                    <h5>Your People</h5>
                    <p>Change requires solutions that integrate all relevant processes and technology to personalize a 
                        solution for you.</p>
                    <h5>Success Metrics</h5>
                    <p>We strive to make sure your business needs are met by developing key success metrics that adapt 
                        to the situation. Allowing you to track your progress.</p>
                    <h5>Unique Needs</h5>
                    <p>We craft the right solution for you, based on your organization's requirements, budget, 
                        and desired outcome.</p>
                    <h5>Lasting Value</h5>
                    <p>We guide and mentor your team to develop competencies and skills, allowing for an 
                        unmatched experience.</p>
                    <h5>People Transformation</h5>
                    <p>To create lasting change, your organization must change how it engages its leaders and employees. 
                        Encouraging people to think and act differently is vital. Involve people in the process, have 
                        conversations, and implement new practices.</p>
                    <h5>Transformation Solutions of all types</h5>
                    <p>Our teams offer solutions for all of the following:</p>
                    <ul>
                        <li>Mergers and acquisitions</li>
                        <li>New technology products</li>
                        <li>Enterprise Resource Planning (ERPs)</li>
                        <li>Customer Relationship Management (CRMs)</li>
                        <li>Digital</li>
                    </ul>
                </div>
             {/*   <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">People and Change Consulting Services</h2>
                  <h6 className="subtitle">We can help your organization with the people side of change:</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Organizational Change Management</h2>
                        <p className="desc">Build an agile and integrated change strategy that 
                        addresses your most challenging program and organization initiatives.</p>
                      </div>
                  </div>
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Leader, Culture and Team Effectiveness</h2>
                        <p className="desc">Shape your culture, build highly effective teams, and influential leaders.</p>
                      </div>
                  </div>
                  <div className="single column is-3">
                      <div className="inner">
                          <span className="line"></span>
                        <h2 className="title">Enterprise Change Management</h2>
                        <p className="desc">Construct change capabilities that genuinely support your transformation journey.</p>
                      </div>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default PeopleChange
